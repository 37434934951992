import '../../assets/css/extraStyles.css'
import Ripples from './Ripples'
import React, { useRef, useEffect } from 'react';

import {motion} from 'framer-motion'



export default function Tile({title, logo, backgroundImg, backgroundColor, text, brandName, Link}) {
  const divRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.style.opacity = '1';
        } else {
          entry.target.style.opacity = '0';
        }
      });
    }, { threshold: 0.5 });

    observer.observe(divRef.current);

    return () => {
      observer.unobserve(divRef.current);
    };
  }, []);

  return (
    <div className='tileContainerOuter'
     style={{backgroundImage: `url(${backgroundImg})`, opacity: 1}}
     ref={divRef}
     >
    <div  className='tileContainerInner'  >
        <h3 className='tileHeader '>
            {title} {brandName}
        </h3>
        <p className='tileText' >{text}</p>
        <a className='exploreBrandLink' href={Link} target='_empty'>
        <Ripples
        imageIn={logo} />
        <span className='exploreBrandText'>Explore</span>
        </a>

    </div>
    </div>
  )
}

