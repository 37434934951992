import React from "react";
import "../assets/css/extraStyles.css"; // import CSS file with styles for the component
import bg2 from '../assets/images/business-meeting2.jpg'
import bg1 from '../assets/images/slide_1.jpg'
import bg3 from '../assets/images/trading.jpg'
import bg4 from '../assets/images/crypto.jpg'
import bg5 from '../assets/images/bg5.jpg'
import bg6 from '../assets/images/bg6.jpg'
import bg7 from '../assets/images/generatednft.jpg'
import bg8 from '../assets/images/bg8.jpg'
import { motion } from "framer-motion";
import ScrollAnimation from "react-animate-on-scroll";


const CompsData = [
  {
    backgroundImg: bg1,
    compName: "WallStreet Forex",
    link:"http://wallstreetforex.net"

  },
  {
    backgroundImg: bg2,
    compName: "WallStreet Schools",
    link: "https://schools.wallstreetgroup.co",

  },
  {
    backgroundImg: bg3,
    compName: "WallStreet Exchange",
    link: "https://exchange.wallstreetgroup.co",
  },
  // {
  //   backgroundImg: bg4,
  //   compName: "WallStreet Crypto",

  // },
  {
    backgroundImg: bg5,
    compName: "WallStreet Crypto",
    link: "https://crypto.wallstreetgroup.co",


  },
  {
    backgroundImg: bg6,
    compName: "WallStreet Media",
    link: "https://media.wallstreetgroup.co",
  },
  // {
  //   backgroundImg: bg7,
  //   compName: "WallStreet NFT",

  // },
  {
    backgroundImg: bg8,
    compName: "WallStreet Consulting",
    link: "https://consulting.wallstreetgroup.co",
  },
]



const WallStreetComps = () => {
  return (
    <div  style={{display: 'flex', flexDirection: 'column', marginTop: "100px", justifyContent: 'center', alignItems: 'center'}} >
    <h1 className="seconHeader goldTextClip " >WallStreet Group <br /> Services</h1>
      <div className="servicesContainer">
        {CompsData.map((item, index)=>(
          <ScrollAnimation
          animateIn="fadeInUp"
          animateOut="fadeInOut"
          animateOnce={false}>
        <motion.div
        whileHover={{scale:1.1}}
         key={index} className="box"
          style={{backgroundImage: `url(${item.backgroundImg})` }} >
          <div className="boxInner" >
            <p>{item.compName}</p>
            <a className="exploreButton" href={item.link} target="_blank" >Explore</a>
            </div>
      </motion.div>
      </ScrollAnimation>
      ))}
    </div>
    </div>
  )
}

export default WallStreetComps;


// return (
//   <div className="servicesContainer">
//     <div key={index} className="box" style={{backgroundImage:`${item.backgroundImg}`}} >
//       <p className="boxInner" >{item.compName}</p>
//     </div>
//     {/* <div className="box box2" style={{backgroundImage:`url(${bg2})`}}>
//     <p>WallStreet <b /> Schools</p>
//     </div>
//     <div className="box box3" style={{backgroundImage:`url(${bg3})`}}>
//     <p>WallStreet <b /> Xchange</p>
//     </div>
//     <div className="box box4" style={{backgroundImage:`url(${bg4})`}}>
//     <p>WallStreet <b /> Crypto</p>
//     </div>
//     <div className="box box4" style={{backgroundImage:`url(${bg4})`}}>
//     <p>WallStreet <b /> Media</p>
//     </div>
//     <div className="box box4" style={{backgroundImage:`url(${bg4})`}}>
//     <p>WallStreet <b /> NFT</p>
//     </div>
//     <div className="box box4" style={{backgroundImage:`url(${bg4})`}}>
//     <p>WallStreet <b /> OTC</p>
//     </div>
//     <div className="box box4" style={{backgroundImage:`url(${bg4})`}}>
//     <p>WallStreet <b /> OTC</p>
//     </div> */}
//   </div>
// )