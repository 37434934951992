import React from 'react'

export default function Ripples({imageIn}) {
  return (
    <div class="outer-circle">
  <div class="inner-circle" >
    <img src={imageIn} alt='Brand Logo' />
  </div>
</div>

  )
}
