import React from 'react';
import ContactForm from "./ContactForm";
import GoogleMapStyle from "./GoogleMapStyle";
import { FiHeadphones , FiMail , FiMapPin } from "react-icons/fi";

const ContactOne = () => {
    return (
        <>
            <div className="row row--15">
                <div className="col-lg-12">
                    <div className="rn-contact-address mt_dec--30">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiHeadphones color='gold'  />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Contact Phone Number</h4>
                                        <p><a href="tel:+234-703-357-19745">+234 (0) 700 222 2222</a></p>
                                        {/* <p><a href="tel:+234-818-752-1015">+234-818-752-1015</a></p> */}
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMail  color='gold'  />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Our Email Address</h4>
                                        <p><a href="mailto:email@wallstreetgroup.co">email@wallstreetgroup.co</a></p>
                                        <p><a href="mailto:wallstreet.acc@gmail.com">wallstreet.acc@gmail.com</a></p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMapPin   color='gold' />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Our Location</h4>
                                        <p>1st Floor, Downtown Mall
Ikenegbu, Owerri, <br /> Imo State, Nigeria</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mt--40 row--15">
                <div className="col-lg-7">
                    <ContactForm formStyle="contact-form-1" />
                </div>
                <div className="col-lg-5 mt_md--30 mt_sm--30">
                <iframe title='Google Map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3971.5214342351205!2d7.037905775511244!3d5.489284894490556!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x104259cd694a7c1f%3A0x9e0eeba75f48f762!2sWallstreet%20Forex!5e0!3m2!1sen!2sng!4v1686587832455!5m2!1sen!2sng" width="100%" height="450" style={{border:0, borderRadius: 15}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        </>
    )
}
export default ContactOne;