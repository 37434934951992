import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import echangeImage from '../../assets/images/exchangesGlobe.png'
import wallstbg1 from '../../assets/images/wallstbg1.jpg'

const TimelineData = [
    {
        id: "1",
        date: "Step-1",
        title: "Exchange your money between currencies with peace of mind at WallStreet Xchange",
        description: "Let us help you achieve a seamless inter-border money transfer and exchange from currency to another at the best fees",
        image: echangeImage,
        workingStep: [
            {
                stepTitle: "Gain your works",
                stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            },
            {
                stepTitle: "Working process",
                stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            },
        ]
    },
    {
        id: "2",
        date: "Step-2",
        title: "On a mission to enrich and empower",
        description: "At Wallstreet Group, our mission is to empower individuals worldwide to achieve financial freedom and become the best version of themselves. We believe that financial education is a key component of personal growth, and we are dedicated to providing high-quality educational resources and tools that help people make more money, build wealth, and improve their lives. As a diverse organization made up of various brands, we are committed to delivering innovative solutions and strategies that enable individuals to achieve their financial goals. We strive to foster a culture of collaboration, excellence, and transparency, where our team members are empowered to think creatively, act with integrity, and always put the customer first.",
        image: wallstbg1,
        workingStep: [
            {
                stepTitle: "Follow this process",
                stepDescription: "Whether through our online courses, investment advice, or other financial products and services, we are dedicated to empowering individuals to take control of their financial futures and create the lives they want to live. At Wallstreet Group, we are driven by a shared vision of a world where everyone has the knowledge, tools, and resources they need to achieve financial freedom and create a better future for themselves and their families.",
            },
            {
                stepTitle: "Working experience",
                stepDescription: "As a diverse organization made up of various brands, we are committed to delivering innovative solutions and strategies that enable individuals to achieve their financial goals. We strive to foster a culture of collaboration, excellence, and transparency, where our team members are empowered to think creatively, act with integrity, and always put the customer first.",
            },
        ]
    },
]

const TimelineOneCopy = () => {
    return (
        <div className="rn-timeline-wrapper timeline-style-one position-relative">
            <div className="timeline-line"></div>
            {/* Start Single Timeline  */}

            {TimelineData.map((data, index) =>(
                <div className="single-timeline mt--50" key={index}>
                    <div className="timeline-dot">
                        <div className="time-line-circle"></div>
                    </div>
                    <div className="single-content">
                        <div className="inner">
                            <div className="row row--30 align-items-center">
                                <div className="order-2 order-lg-1 col-lg-6 mt_md--40 mt_sm--40">
                                    <div className="content">
                                        {/* <ScrollAnimation
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                            <span className="date-of-timeline">{data.date}</span>
                                        </ScrollAnimation> */}

                                        <ScrollAnimation
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={false}>
                                            <h2 className=" standard-header goldTextClip">{data.title}</h2>
                                        </ScrollAnimation>

                                        <ScrollAnimation
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={false}>
                                            <p className="description">{data.description}</p>
                                        </ScrollAnimation>

                                        <div className="row row--30">
                                            {data.workingStep.map((data, index)=>(
                                                <div className="col-lg-6 col-md-6 col-12" key={index}>
                                                    {/* <div className="working-list">
                                                        <ScrollAnimation
                                                        animateIn="fadeInUp"
                                                        animateOut="fadeInOut"
                                                        animateOnce={true}>
                                                            <h5 className="working-title">{data.stepTitle}</h5>
                                                        </ScrollAnimation>

                                                        <ScrollAnimation
                                                        animateIn="fadeInUp"
                                                        animateOut="fadeInOut"
                                                        animateOnce={true}>
                                                            <p>{data.stepDescription}</p>
                                                        </ScrollAnimation>
                                                    </div> */}
                                                </div>
                                            ))}
                                        </div>
                                        <div className="btn-gold-gradient">
                                            <ScrollAnimation
                                            animateIn="fadeInUp"
                                            animateOut="fadeInOut"
                                            animateOnce={false}>
                                                <a className="" style={{color:'white'}} href="/contact"><span>Get Started Now</span></a>
                                            </ScrollAnimation>
                                        </div>
                                    </div>
                                </div>
                                <div className="order-1 order-lg-2 col-lg-6">
                                    <div className="thumbnail">
                                        <img className="w-100" src={data.image} alt="Corporate React Template" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}

            {/* End Single Timeline  */}
        </div>
    )
}

export default TimelineOneCopy;
