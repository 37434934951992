import React from "react";
import { FiActivity, FiCast, FiMap } from "react-icons/fi";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
  {
    icon: <FiActivity />,
    title: "Awarded Design",
    description:
      "There are many variations variations  of passages of Lorem Ipsum available, but the majority have suffered.",
  },
  {
    icon: <FiCast />,
    title: "Design & Creative",
    description:
      "Passages there are many variations variations  of of Lorem Ipsum available, but the majority have suffered.",
  },
  {
    icon: <FiMap />,
    title: "App Development",
    description:
      "Variations There are many variations  of passages of Lorem Ipsum available, but the majority have suffered.",
  },
];

const ServiceOne = ({ textAlign, serviceStyle }) => {
  return (
    <div className="row row--15 service-wrapper">
      <p style={{ fontSize: 25, textAlign: "center" }}>
        We have years of expertise transforming people who have zero-coding
        knowledge and zero tech knowledge into highly-paid software engineers
        and other tech fields. Our professional tutors have over 20 years of
        experience among them and have helped people like you gain their highly
        paid jobs and stable jobs in a modern world.
      </p>
      {ServiceList.map((val, i) => (
        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
          <ScrollAnimation
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}
          >
            <div className={`service ${serviceStyle} ${textAlign}`}>
              <div className="icon">{val.icon}</div>
              <div className="content">
                <h4 className="title w-600">
                  <Link
                    to="#service"
                    dangerouslySetInnerHTML={{ __html: val.title }}
                  ></Link>
                </h4>
                <p
                  className="description b1 color-gray mb--0"
                  dangerouslySetInnerHTML={{ __html: val.description }}
                ></p>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      ))}
    </div>
  );
};
export default ServiceOne;
