import React , {useState} from 'react';
import axios from 'axios';

// import emailjs from 'emailjs-com';

const Result = () => {
    return (
        <p className="success-message">Your Message has been successfully sent. I will contact you soon.</p>
    )
}

function ContactForm({props , formStyle}) {
    // const [ result,showresult ] = useState(false);
     const [successMessage, setSuccessMessage] = useState('');
     const [errorMessage, setErrorMessage] = useState('');
     const [formSuccess, setFormSuccess] = useState('none');
    const [showModal, setShowModal] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();

         // Get form data
        const formData = new FormData(event.target);

        try {
             // Send form data to the server
            const response = await fetch('https://script.google.com/macros/s/AKfycbwaUfbmOn_TVATwukO0alvjQb3wdrVIL3iCh2SPNhs9xTWYnQXpoiMMwmz0W1r4_Ga99A/exec', {
                method: 'POST',
                body: formData,
              });

            //   console.log(response);
               // Check if the response is successful
                if (response.ok) {
                    // Show the success modal
                    // setShowModal(true);
                    setFormSuccess("flex")
                    // alert("Thank you for sending your enquiry, we will get back to you within 24 hours. Please check your inbox and spam for message from Wallstreet Group. Regards")
                    //Set the successmessage to be displayed
                    setSuccessMessage("Thank you for sending your enquiry, we will get back to you within 24 hours.  Please check your inbox and spam for message from Wallstreet Group. Regards");
                } else {
                    // Handle error case
                    alert('Error submitting form. Please check your network connection and try again. If problem persists, please email or call us directly. See contact details above. Thank you.');
                }
                } catch (error) {
                // Handle network or server error
                console.log('Error submitting form:', error);
                }

                //Reset the form
                event.target.reset()
            };

    // setTimeout(() => {
    //     showresult(false);
    // }, 5000);

    return (
        <form className={`${formStyle}`}  onSubmit={handleSubmit}>
            <div className="form-group">
            <label for="FullName">Full Name:</label>
                <input
                type="text"
                name="FullName"
                placeholder="Your Name"
                required
                />
            </div>

            <div className="form-group">
            <label for="Email">Email address:</label>
                <input
                type="email"
                name="Email"
                placeholder="Email Address"
                required
                />
            </div>

            <div className="form-group">
            <label for="Phone">Phone Number (Please start with country code, eg +234-7010000101):</label>
                <input
                type="text"
                name="Phone"
                placeholder="Phone Number"
                required
                />
            </div>


            <div className="form-group">
            <label for="Subject">Subject:</label>
                <input
                type="text"
                name="Subject"
                placeholder="Subject"
                required
                />
            </div>

            <div className="form-group">
            <label for="Message">Message detail</label>
                <textarea
                name="Message"
                placeholder="Your Message"
                required
                >
                </textarea>
            </div>

            <div className="form-group">
                <button className="btn-gold-gradient font-black" type="submit">Submit Now</button>
            </div>

            {/* <div className="form-group">
                {result ? <Result /> : null}
            </div> */}
            {/* Modal component */}

            {/* Display success message if form successfully submitted */}
            <div className='formSuccess' style={{display: formSuccess}} >
                <p>{successMessage}</p>
            </div>
            {/* {showModal && (
                <div className="modal">
                <div className="modal-content">
                    {successMessage && <p>{successMessage}</p>}
                    {errorMessage && <p>{errorMessage}</p>}
                    <button onClick={() => setShowModal(false)}>Close</button>
                </div>
                </div>
            )} */}
        </form>
    )
}
export default ContactForm;
