
import React from 'react';
import Separator from "../elements/separator/Separator";
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import BlogClassicData from '../data/blog/BlogList.json';
import secImage from '../assets/images/business-meeting2.jpg'
import WallStreetComps from '../elements/WallStreetGroupSegments'
import Typed from "react-typed";
import "../assets/css/extraStyles.css";
import Tile from '../elements/ElementByGreenBrain/Tile';
import consultBg from '../assets/images/wallstbg4.jpg'
import cryptoBg from '../assets/images/wallstbg1.jpg'
import mediaBg from '../assets/images/wallstbg2.jpg'
import wscryptoLogo from '../assets/images/Wallstreet-logo-crypto.png'
import wsConsultLogo from '../assets/images/Wallstreet-logo-consulting.png'
import wsMediaLogo from '../assets/images/Wallstreet-logo-media.png'
import wsOTCLogo from '../assets/images/Wallstreet-logo-otc.png'
import LogoScroll from '../elements/ElementByGreenBrain/LogosScroll';
import TimelineOneCopy from '../elements/timeline/TimelineOne-Copy'
import tradingClass from "../assets/images/trading-teacher.png"
import softwaredev from "../assets/images/software-dev.png"
import ScrollAnimation from "react-animate-on-scroll";
import Contact from '../elements/contact/Contact';


var BlogListData = BlogClassicData.slice(0, 3);

// const BannerData = [
//     {
//         image: firstImage,
//         title: "WallStreet Group",
//         description: "We help our clients and students succeed by mastering wealth creation skills <br /> and providing genuine opportunities."
//     },
//     {
//         image: secImage,
//         title: "This is <br /> WallStreet Group",
//         description: "We help our clients and students succeed by mastering wealth creation skills <br /> and providing genuine opportunities."
//     },
// ]


const AboutOne = () => {
    return (
      <>
      <div className="rwt-about-area rn-section-gap">
        <div className="container">
          <div className="row row--30 align-items-center">
            <div className="col-lg-5">
              <div className="thumbnail">
                <img
                  className="w-100 sideImage"
                  src={tradingClass}
                  alt="About Images"
                />
              </div>
            </div>

            <div className="col-lg-7 mt_md--40 mt_sm--40">

              <div className="content">

                <div className="section-title">
                  <ScrollAnimation
                    animateIn="fadeInRight"
                    animateOut="fadeInOut"
                    animateOnce={false}
                    >
                  <h2 className="title">
                    Forex education per excellence <br />{" "}
                    <Typed
                      className="theme-gradient-golden standard-header"
                      strings={["Consulting.", "Finance.", "Agency."]}
                      typeSpeed={80}
                      backSpeed={5}
                      backDelay={1000}
                      loop
                    />
                  </h2>
                  </ScrollAnimation>


                  <p>
                  Are you interested in the world of foreign exchange, but feel intimidated by the jargon and complexity of trading? Fear not, because we offer topnotch forex education that caters to individuals with zero knowledge of the trading world.

Our team of experienced traders and educators have developed a comprehensive curriculum that covers everything from the basics of forex trading to advanced strategies used by professionals. We understand that starting out can be overwhelming, so we've designed our courses to be approachable and easy to follow.
                  </p>
                  <p>
                  Join us on our mission to democratize access to forex education and help people achieve financial freedom through trading. Enroll in our topnotch forex education program today and take the first step towards becoming a successful trader.{" "}
                  </p>
                  <div className="read-more-btn mt--40">
                    <a className="btn-default" href="https://wallstreetforex.net">
                      <span>More About WallStreet FX</span>
                    </a>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div className="rwt-about-area rn-section-gap">
        <div className="container">
          <div className="row row--30 align-items-center">

            <div className="col-lg-7 mt_md--40 mt_sm--40">
              <div className="content">
                <div className="section-title">
                <ScrollAnimation
                    animateIn="fadeInLeft"
                    animateOut="fadeInOut"
                    animateOnce={false}
                    >
                  <h2 className="title">
                    Become a <br />{" "}
                    <Typed
                      className="theme-gradient-golden standard-header"
                      strings={["Web developer.", "App developer.", "Cyber-sec expert.", "Anything..."]}
                      typeSpeed={80}
                      backSpeed={5}
                      backDelay={1000}
                      loop
                    />
                  </h2>
                  </ScrollAnimation>
                  <p>
                  Looking to break into the world of technology but don't know where to start? Our topnotch IT education program is designed to help individuals with zero knowledge of the tech industry jumpstart their careers. From software development to cybersecurity, our courses cover a range of in-demand tech careers. Join us to learn the fundamentals of programming languages, database management, cloud computing, and more.

                  </p>
                  <p>
                  Our expert instructors will guide you through practical projects and prepare you for careers such as software developer, network administrator, data analyst, cybersecurity analyst, and IT project manager.
                  Enroll today and take the first step towards a rewarding career in tech{" "}
                  </p>
                  <div className="read-more-btn mt--40">
                    <a className="btn-default" href="https://schools.wallstreetgroup.co" target='_empty' >
                      <span>Explore WallStreet Schools</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-5">
              <div className="thumbnail">
                <img
                  className="w-100 sideImage"
                  src={softwaredev}
                  alt="About Images"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
    );
  };


const Startup = () => {
    return (
        <div >
            <SEO title="WallStreet Group" />
            <main className="page-wrapper">
                <HeaderOne btnStyle="btn-small btn-icon round" HeaderSTyle="header-transparent" />
                <div className=" bg_image HeroContainer " style={{backgroundImage: `url(${secImage})`}}>
                                <div className=" heroBoxInner">
                                    <div className="row row--30 align-items-center">
                                        <div className="col-lg-12">
                                            <div className="inner text-center">
          {/* Start Slider Area  */}
        <div className="slider-area slider-style-1 bg-transparent height-950">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
              {/* <h1 className="title seconHeader theme-gradient-golden">WallStreet Group</h1> */}
              <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  animateOnce={false}>
                <div className="inner text-center">
                  {/* <span className="subtitle">DIGITAL CONSULTING AGENCY</span> */}
                  <h1 className="title theme-gradient-golden display-two goldTextClip">
                    WallStreet Group <br />{" "}
                    <Typed
                      strings={["Forex.", "Consulting.", "IT Education.", "NFTs.", "Crypto.", "Exchange.", "OTC."]}
                      typeSpeed={80}
                      backSpeed={5}
                      backDelay={1000}
                      loop
                    />
                  </h1>
                  {/* <p className="description">
                  We help our clients and students succeed by mastering wealth creation skills <br /> and providing genuine opportunities.
                  </p> */}
                  {/* <div className="button-group">
                    <a
                      className="btn-default btn-medium round btn-icon"
                      target="_blank"
                      href="https://themeforest.net/checkout/from_item/33571911?license=regular"
                    >
                      Purchase Now{" "}
                      <i className="icon">
                        <FiArrowRight />
                      </i>
                    </a>
                    <Link
                      className="btn-default btn-medium btn-border round btn-icon"
                      to="#"
                    >
                      Contact Us{" "}
                      <i className="icon">
                        <FiArrowRight />
                      </i>
                    </Link>
                  </div> */}
                </div>
                </ScrollAnimation>
                <p className="description" >We help our clients and students succeed by mastering wealth creation skills <br /> and providing genuine opportunities.</p>
              </div>

            </div>

          </div>
          <LogoScroll />
        </div>


        {/* End Slider Area  */}

                                                {/* <div className="button-group mt--30">
                                                    <a className="btn-default btn-large round" href="">Explore Our Services</a>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                {/* Start Slider Area  */}
                {/* <Slider className="slider-area slider-style-4 variation-2 slider-dot rn-slick-dot rn-slick-arrow" {...BannerActivation}>
                    {BannerData.map((data, index) => (
                        <div key={index} className="single-slide">
                            <div className="height-950 bg-overlay bg_image" style={{backgroundImage: `url(${process.env.PUBLIC_URL} ${data.image})`}}>
                                <div className="container">
                                    <div className="row row--30 align-items-center">
                                        <div className="col-lg-12">
                                            <div className="inner text-center">
                                                <h1 className="title" dangerouslySetInnerHTML={{__html: data.title}}></h1>
                                                <p className="description" dangerouslySetInnerHTML={{__html: data.description}}></p>
                                                <div className="button-group mt--30">
                                                    <a className="btn-default btn-large round" href="">Explore Our Services</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider> */}
                {/* End Slider Area  */}

                <WallStreetComps />
                {/* <Separator />
                <div className="rwt-elements-area rn-section-gap">
                    <div className="container">
                        <div className="row mb--25">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Our Experience"
                                    title = "25+ years work Experience"
                                    description = ""
                                />
                            </div>
                        </div>
                        <div className="col-lg-10 offset-lg-1 mt--10">
                            <CircleProgress />
                        </div>
                    </div>
                </div> */}


                {/* <Separator />
                <div className="rwt-timeline-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Working Process"
                                        title = "Our Working Process."
                                    />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-10 offset-lg-1 mt--50">
                                <TimelineTwo classVar="no-gradient"  />
                            </div>
                        </div>
                    </div>
                </div> */}

                <Separator />
                {/* <div className="rwt-elements-area rn-section-gap">
                    <div className="container-fluid plr--30">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Portfolio"
                                    title = "Our Completed Project!"
                                    description = ""
                                />
                            </div>
                        </div>
                        <PortfolioOne Column="col-lg-4 col-md-6 mt--30 box-grid-layout no-overlay" />
                    </div>
                </div> */}



                <Separator />
                {/* Start Service Area  */}
                {/* <div className="rn-service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "What we can do for you"
                                    title = "Services provide for you."
                                    description = ""
                                    />
                            </div>
                        </div>
                        <ServiceFour
                            serviceStyle = "service__style--1 icon-circle-style with-working-process"
                            textAlign = "text-center"
                            />
                    </div>
                </div> */}
                {/* End Service Area  */}

                <AboutOne />

                <Separator />
                <div className='tilesGroupContainer'>
                  <Tile
                  title='Join the leading currency exchange'
                  brandName={<span className='goldTextClip standard-header' >WallStreet Exchange</span>}
                  backgroundImg="https://images.pexels.com/photos/5240545/pexels-photo-5240545.jpeg?auto=compress&cs=tinysrgb&w=1600"
                  text="Looking for a reliable and hassle-free way to convert your cryptocurrency to physical cash? Look no further than WallStreet Exchange. Our service is the best in the business, offering a seamless and secure process that lets you turn your crypto into real-world money with ease."
                   logo={wsOTCLogo}
                   Link="https://exchange.wallstreetgroup.co"
                   />

                  <Tile
                  title="Go from ideas to international business with"
                  brandName={<span className='goldTextClip standard-header' >WallStreet Consulting</span>}
                  backgroundImg={consultBg}
                  text="Looking to turn your business idea into a thriving international enterprise? WallStreet Consulting can help. Our expert team has the knowledge and experience to guide individuals, businesses, and government agencies alike to success. Let us take your idea to the next level."
                  logo={wsConsultLogo}
                  Link="https://consulting.wallstreetgroup.co"
                  />

                </div>

                <Separator />
                <div className='tilesGroupContainer'>
                  <Tile
                  title="Learn the way of profit in the blockchain with"
                  brandName={<span className='goldTextClip standard-header' >WallStreet Crypto</span>}
                  backgroundImg={cryptoBg}
                  text="Ready to become a blockchain hero? Join WallStreet Crypto - the premier mentoring group for those looking to succeed in the world of blockchain. Our expert mentors provide the knowledge and guidance needed to turn your blockchain business dreams into reality, no matter your starting point."
                  logo={wscryptoLogo}
                  Link="https://crypto.wallstreetgroup.co"

                  />
                  <Tile
                  title="Reach your most profitable audience with"
                  brandName={<span className='goldTextClip standard-header' >WallStreet Media</span>}
                  backgroundImg={mediaBg}
                  text="Want to reach millions of potential customers for your business? Let WallStreet Media help. Our digital and traditional media expertise ensures that your message is delivered effectively to your target audience, helping you to achieve the business growth and success you deserve."
                  logo={wsMediaLogo}
                  Link="https://media.wallstreetgroup.co"
                  />
                </div>

                <Separator />
                {/* <WideTile /> */}
                <TimelineOneCopy />
                <Separator />

                {/* <div className="blog-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Latests News"
                                    title = "Our Latest News."
                                    description = "We provide company and finance service for <br /> startups and company business."
                                />
                            </div>
                        </div>
                        <div className="row row--15">
                            {BlogListData.map((item) => (
                                <div key={item.id} className="col-lg-4 col-md-6 col-12 mt--30">
                                    <BlogList StyleVar="box-card-style-default" data={item} />
                                </div>
                            ))}
                        </div>
                    </div>
                </div> */}
                <Contact />
                {/* <FooterTwo /> */}
            </main>
        </div>
    )
}

export default Startup;
