import React from 'react';
import wscryptoLogo from '../../assets/images/Wallstreet-logo-crypto.png'
import wsSchoolLogo from '../../assets/images/Wallstreet-logo-main.png'
import wsConsultLogo from '../../assets/images/Wallstreet-logo-consulting.png'
import wsExchangeLogo from '../../assets/images/Wallstreet-logo-exchange.png'
import wsMediaLogo from '../../assets/images/Wallstreet-logo-media.png'
import wsNftLogo from '../../assets/images/Wallstreet-logo-nft.png'
import wsOTCLogo from '../../assets/images/Wallstreet-logo-otc.png'

const logos =[wscryptoLogo, wscryptoLogo,
    wsConsultLogo, wsOTCLogo, wsOTCLogo,
    wsExchangeLogo, wsSchoolLogo, wsMediaLogo, wsNftLogo]

const LogoScroll = () => {
  return (
    <div className="logoScrollContainer">
        {logos.map((logo, index)=>{
            return(
            <div key={index} className='logoScrollItem' >
                <img src={logo} />
            </div>
            )
        })}
    </div>
  );
};

export default LogoScroll;
